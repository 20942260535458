<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-6">
        <h1>{{ `${cashRegister.name}` }}</h1>
      </span>
      <div class="col-6 text-right"></div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ cashRegister.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CODE") }}</dt>
          <dd class="col-sm-8">
            {{ cashRegister.code }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="cashRegister.organization" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
            cashRegister.establishment
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.ESTABLISHMENT") }}</dt>
          <dd class="col-sm-8">
            <establishment :establishment="cashRegister.establishment" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS) &&
            cashRegister.serviceCenter
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.SERVICECENTER") }}</dt>
          <dd class="col-sm-8">
            <serviceCenter :serviceCenter="cashRegister.serviceCenter" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS) &&
            cashRegister.cafeteria
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.CAFETERIA") }}</dt>
          <dd class="col-sm-8">
            <cafeteria :cafeteria="cashRegister.cafeteria" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(cashRegister.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(cashRegister.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import IconCheck from "@/components/IconCheck.vue";
import Cafeteria from "@/components/Cafeteria.vue";
import Establishment from "@/components/Establishment.vue";
import ServiceCenter from "@/components/ServiceCenter.vue";

export default {
  name: "cash-register-view-global",
  components: { IconCheck, Cafeteria, ServiceCenter, Establishment },

  props: ["cashRegister"],

  data() {
    return {};
  },

  created() {},

  methods: {
    cashRegisterUpdated() {
      this.$emit("cashRegisterUpdated", true);
    },
  },

  mounted() {},

  watch: {
    cashRegister(cashRegister) {},
  },
};
</script>
