export default {
  type: "cash-registers",
  name: null,
  code: null,
  relationshipNames: [
    "organization",
    "establishment",
    "serviceCenter",
    "cafeteria",
    "warehouse",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  establishment: {
    type: "establishments",
    id: null,
  },
  warehouse: {
    type: "warehouses",
    id: null,
  },
  serviceCenter: {
    type: "service-centers",
    id: null,
  },
  cafeteria: {
    type: "cafeterias",
    id: null,
  },
  allowedUsers: [],
};
